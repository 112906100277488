import React, { Component } from 'react';

export default class Home extends Component {
	render() {
		return (
			<div>
				<div className="give-me-space blog">
					<h2>
						Elite Invite
					</h2>
					<p className="normal">
						From the 30th of May to the 1st of June Jetset is organizing Elite Invite in collaboration with Stad Leuven and EUF. This tournament will bring together the top 8 European teams in the 3 divisions (Mixed, Open and Women). Free entry to come experience the kick off the new European season.
					</p>
					<b>
						Practicals
					</b>
					<p className='normal'>Date: 30/05-1/06/2025 9h-20h (16h on Sunday) <br /> Address: Domeinstraat 61, 3010 Kessel-Lo</p>
				</div>
			</div >
		);
	}
}
